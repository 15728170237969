import {$host} from './index';

export const fetchTweet = async () => {
    const tweet = await $host.get('tweet/:?id=3');
    return tweet;
}

export const fetchRandomTweet = async () => {
    const tweet = await $host.get('tweet/rand');
    return tweet;
}


export const fetchEmotionsAndRandTweet = async () => {
    const tweetAndEmotions = await $host.get('tweetAndEmotionRouter/emotionsAndRandTweet');
    return tweetAndEmotions;
}

export const fetchEmotions = async () => {
    const emotions = await $host.get('emotion');
    return emotions;
}

export const createEmotionToTweetTable = async (count, emotionId, tweetId) => {  //для передачи информации о выбранной эмоции на сервер
    const table = await $host.post('emotionToTweets/', {count, emotionId, tweetId});
    return table;
}