import './App.css';

import React, {useState, useCallback, useContext, useEffect} from 'react';
import {render} from 'react-dom';
import {useModal} from 'react-hooks-use-modal';

import ButtonOptionAnswer from "./ButtonOptionAnswer/ButtonOptionAnswer";
import MainWindow from "./MainWindow/MainWindow";
import {Context} from "./index";
import {observe} from "mobx";
import {observer} from "mobx-react-lite";
import {
    createEmotionToTweetTable,
    fetchEmotions,
    fetchTweet,
    fetchRandomTweet,
    fetchEmotionsAndRandTweet
} from "./http/tweetAPI";

const App = observer(() => {

    const {tweet} = useContext(Context);
    const [count, setCount] = useState(0);

    function increment(){
        setCount(count + 1);
    }

    function printN(){

        if(tweet.emotions.length == 3) {
            return (<div>
                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[0].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[0].name}</button>
                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[1].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[1].name}</button>
                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[2].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[2].name}</button>
            </div>);
        }
        else if(tweet.emotions.length == 8){
            return(<div>
                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[0].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[0].name}</button>
                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[1].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[1].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[2].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[2].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[3].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[3].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[4].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[4].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[5].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[5].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[6].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[6].name}</button>

                <button onClick={() => {
                    const t = createEmotionToTweetTable(1, tweet.emotions[7].id, tweet.text.id);
                    increment();
                    fetchEmotionsAndRandTweet().then(data => {
                        tweet.setEmotions(data.data.emotions);
                        tweet.setText(data.data.tweet);
                    })
                }}>{tweet.emotions[7].name}</button>
                </div>)

        }
        else {
            console.log('Неверное число эмоций!');
            return (
                <div>Неверное число эмоций</div>
            )
        }
    }

    useEffect(() => {
        /*fetchEmotions().then(data => {
            tweet.setEmotions(data.data);
        })
        fetchRandomTweet().then(data => {
            tweet.setText(data.data);
        });*/

        fetchEmotionsAndRandTweet().then(data => {
            tweet.setEmotions(data.data.emotions);
            tweet.setText(data.data.tweet);
        })



    }, [])


    return (
        <div className="App">
            <main>

            </main>

            <MainWindow>
                <h2>
                    С какой эмоцией автор писал данный пост? Выберете наиболее подходящий вариант
                </h2>

                <h7 className='counter'>Размечено текстов: {count}</h7>

                <p className='text_tweet'>
                    {tweet.text && <>
                        "{tweet.text.text}"
                    </>}
                </p>
                <div>

                    {tweet.emotions.length && <>

                        {printN()}

                    </>}

                </div>
            </MainWindow>
        </div>
    );
});


export default App;
