import {makeAutoObservable} from "mobx";

export default class TweetStore{
    constructor() {
        this._text = '';
        this._emotions = [];
        makeAutoObservable(this);
    }

    setEmotions(emotions){
        this._emotions = emotions;
    }


    setText(text){
        this._text = text;
    }

    /*setEmotion(emotion){
        this._emotion = emotion;
    }*/

    get text(){
        return this._text;
    }

    get emotions(){
        return this._emotions;
    }
}