import React from 'react';
import './MainWindow.css'

const MainWindow = ({children}) => {
    return (
        <div className='mainWindow'>
            {children}
        </div>
    );
};

export default MainWindow;